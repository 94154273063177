import { Component, OnInit } from "@angular/core";
import { HttpService } from "./service/http.service";
import { DataService } from "./service/data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {}
