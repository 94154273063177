import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { TorneoService } from "./torneo.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    private httpService: HttpService,
    private torneoSv: TorneoService
  ) {
    if (typeof Storage !== "undefined") {
      // LocalStorage disponible
    } else {
      // LocalStorage no soportado en este navegador
    }
  }

  idLibre = "5c7de594fb9d9320997a41af";

  teams = [];
  selectedTeam = {} as any;

  reglamento = null;
  favouritesSelected = [];

  news = [
    {
      name: "Lifa no tiene noticias para mostrarte..",
      id: 1,
      team: "12",
    },
  ];

  tribunal = [
    {
      name: "Lifa no tiene noticias para mostrarte..",
      id: 1,
      team: "12",
    },
  ];

  statusWB;
  loadError;
  err;

  needRouterOutlet = false;
  teamsShow = [];

  getReglamento() {
    if (this.reglamento == null) {
      this.httpService.getReglamento().subscribe((data) => {
        if (data) {
          this.reglamento = data.srcPdf;
          return this.reglamento;
        }
      });
    } else {
      return this.reglamento;
    }
  }

  loadDataSelect() {}

  loadData() {
    console.log("PEPE");
    this.httpService.getContenedores().subscribe((contenedores) => {
      this.torneoSv.contenedores = contenedores;

      if (contenedores.length > 0) {
        this.torneoSv.contenedorSelect = contenedores[0];
      }

      this.httpService.getTorneos().subscribe(
        (data) => {
          this.torneoSv._torneos = data;
          if (data.length > 0) {
            if (this.torneoSv.contenedorSelect) {
              const torneo = data.filter((x) => {
                let resp = false;

                if (
                  x.contenedor &&
                  x.contenedor == this.torneoSv.contenedorSelect.id
                ) {
                  resp = true;
                }
                return resp;
              });
              this.torneoSv.torneos = torneo;
              if (torneo.length > 0) {
                this.torneoSv.onTorneoSelectChange(torneo[0]);
                this.torneoSv.torneoSelect = torneo[0];
              }
            } else {
              this.torneoSv.onTorneoSelectChange(data[0]);
              this.torneoSv.torneoSelect = data[0];
            }
          }

          this.statusWB = true;
          this.loadError = false;
          this.err = 0;
        },
        (err) => {
          this.err++;
          if (this.err > 2) {
            this.loadError = true;
          } else {
            setTimeout(() => {
              this.loadData();
            }, 2000);
          }
        }
      );
    });
  }

  sortNewsbyTeam() {
    if (this.selectedTeam) {
      const idTeam = this.selectedTeam.id;
      this.news.sort(function (a, b) {
        if (a.team == idTeam) {
          return -1;
        } else if (b.team == idTeam) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  selectFavoriteTeam(team) {
    this.selectedTeam = team;
    localStorage.setItem("fav_team", team.id);

    let favouritesSelected = JSON.parse(
      localStorage.getItem("fav_teams_visit")
    );
    if (favouritesSelected) {
      if (favouritesSelected.length > 15) {
        localStorage.clear();
        localStorage.setItem("fav_team", team.id);
        favouritesSelected = [];
      }

      if (
        favouritesSelected.find(function (element) {
          return element == team.id;
        }) == undefined
      ) {
        favouritesSelected.push(team.id);
      } else {
        favouritesSelected = favouritesSelected;
      }
    } else {
      favouritesSelected = [];
      favouritesSelected.push(team.id);
    }
    localStorage.setItem("fav_teams_visit", JSON.stringify(favouritesSelected));
  }
  getTeams() {
    if (this.teams.length == 0) {
      this.refreshTeams();
    }
  }

  sortTeams() {
    let storedFavourite = localStorage.getItem("fav_team");
    let storedFavourites = JSON.parse(localStorage.getItem("fav_teams_visit"));

    this.selectedTeam = this.teams.find((x) => x.id == storedFavourite);

    if (storedFavourite && storedFavourites) {
      this.teams.sort(function (a, b) {
        if (a.id == storedFavourite) {
          return -1;
        }
        if (b.id == storedFavourite) {
          return 1;
        } else if (
          storedFavourites.find(function (element) {
            return element == a.id;
          }) != undefined
        ) {
          return -1;
        } else if (
          storedFavourites.find(function (element) {
            return element == b.id;
          }) != undefined
        ) {
          return 1;
        } else {
          return Math.random() - 0.5;
        }
      });
    } else if (storedFavourite) {
      if (this.teams) {
        this.teams.sort(function (a, b) {
          if (a.id == storedFavourite) {
            return -1;
          } else if (b.id == storedFavourite) {
            return 1;
          } else {
            return Math.random() - 0.5;
          }
        });
      }
    } else {
      this.teams.sort(function () {
        return Math.random() - 0.5;
      });
    }
  }
  refreshTeams() {
    this.httpService.getTeams().subscribe((data) => {
      this.teams = data;
    });
  }
}
