import { Injectable } from "@angular/core";

export interface tablaPositionIndex {
  indexInTable;
  idCategory;
  idTorneo;
}

@Injectable({
  providedIn: "root",
})
export class DataPersistenteService {
  constructor() {}

  // Tabla de Posicionas Cargadas por sesion

  tablas = [];
  tablasPosition: tablaPositionIndex[] = [];
  // Tablas contiene todas las tablas generadas en una sesion

  pushOnTablas(tabla, idCategory, idTorneo) {
    this.tablas.push(tabla);
    let newIndex = this.tablas.length - 1;
    let tablaPos: tablaPositionIndex = {
      indexInTable: newIndex,
      idCategory,
      idTorneo,
    };
    this.tablasPosition.push(tablaPos);
  }

  findOnTablas(idCategory, idTorneo) {
    let tableData = this.tablasPosition.find((e) => {
      return e.idCategory == idCategory && e.idTorneo == idTorneo;
    });

    if (tableData) {
      return this.tablas[tableData.indexInTable];
    } else {
      return null;
    }
  }

  // Fin de Tabla de Posiciones Cargadas  por sesion
}
