import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from "@angular/core";
import { TorneoService } from "../../service/torneo.service";
import { HttpService } from "../../service/http.service";
import { DataService } from "../../service/data.service";
import { DataPersistenteService } from "../data-persistente.service";

@Component({
  selector: "table-position-md",
  template: `
    <style>
      .special {
        background-color: rgba(7, 173, 7, 0.5);
      }
    </style>
    <br />

    <span class="sr-only">Loading...</span>
    <div class="row">
      <div class="col"></div>

      <div class="col">
        <span
          style="  white-space: nowrap; 
  text-overflow: clip;  "
        >
          {{ _nameTorneo }}:
          <br />
          Categoría: {{ torneoSv.categorySelect.name }}
        </span>
      </div>

      <div class="col"></div>
    </div>
    <table class="table" style="width: 100%;">
      <thead class="thead-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">Club</th>
          <th scope="col">PJ</th>
          <th scope="col">G</th>
          <th scope="col">E</th>
          <th scope="col">P</th>
          <th scope="col">GF</th>
          <th scope="col">GC</th>
          <th scope="col">DG</th>
          <th scope="col">Pts</th>
        </tr>
      </thead>

      <tbody>
        <i class="fas fa-spinner fa-spin " *ngIf="loaddingData"></i>

        <tr
          *ngFor="let tableTeam of table; let i = index"
          [class.special]="tableTeam.team.id == dataService.selectedTeam?.id"
        >
          <th scope="row">{{ i + 1 }}</th>
          <td style="white-space: nowrap;">
            <img
              *ngIf="tableTeam.team"
              style="width: 20px; height: 20px;"
              [src]="tableTeam.team?.logoUrl"
            />
            {{
              tableTeam.team?.publicName
                ? tableTeam.team?.publicName
                : tableTeam.team?.name
            }}
          </td>

          <td>{{ tableTeam?.pj }}</td>
          <td>{{ tableTeam?.g }}</td>
          <td>{{ tableTeam?.e }}</td>
          <td>{{ tableTeam?.p }}</td>
          <td>{{ tableTeam?.gf }}</td>
          <td>{{ tableTeam?.gc }}</td>
          <td>{{ tableTeam?.dg }}</td>
          <td>{{ tableTeam?.pts }}</td>
        </tr>
      </tbody>
    </table>
  `,
  styles: [],
})
export class TablePositionMdComponent implements OnInit, OnDestroy, OnChanges {
  loaddingData = false;
  _nameTorneo;

  @Input()
  set nameTorneo(name: string) {
    this._nameTorneo = name;
  }

  @Input()
  set idCat(idCat: string) {
    this._idCat = idCat;
  }

  @Input()
  set idFutbolEvent(idFutbolEvent: string) {
    this._idFutbolEvent = idFutbolEvent;
  }

  private _teams = [];
  private _idCat: string;
  private _idFutbolEvent: string;
  constructor(
    public torneoSv: TorneoService,
    private http: HttpService,
    private dataService: DataService,
    private persistent: DataPersistenteService
  ) {}

  ngOnInit() {}
  ngOnDestroy() {
    this.idCat = null;
    this.idFutbolEvent = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    var idLibre = "5c7de594fb9d9320997a41af";
    this.loaddingData = true;
    this.table = [];
    const idCat: SimpleChange = changes.idCat;
    if (idCat) this._idCat = idCat.currentValue;
    const idFutbolEvent: SimpleChange = changes.idFutbolEvent;
    if (idFutbolEvent) {
      this._idFutbolEvent = idFutbolEvent.currentValue;
    }
    const nameTorneo: SimpleChange = changes.nameTorneo;
    if (nameTorneo) {
      this._nameTorneo = nameTorneo.currentValue;
    }

    if (!idFutbolEvent && !idCat && !nameTorneo) {
      this._idCat = null;
      this._idFutbolEvent = null;
      this._nameTorneo = "";
    } else {
      if (this._idCat == undefined) this._idCat = null;

      this.http
        .getTablePosition(this._idFutbolEvent, this._idCat)
        .subscribe((data) => {
          console.log(data);
          if (data && data.table) {
            this.table = data.table;
            var idxRemove;
            idxRemove = this.table.findIndex((e) => e.team == idLibre);
            if (idxRemove > -1) this.table.splice(idxRemove, 1);

            if (this._teams.length == 0)
              this.http.getTeams().subscribe((teams) => {
                this._teams = teams;
                for (let index = 0; index < data.table.length; index++) {
                  const idTeam = data.table[index].team;
                  data.table[index].team = this._teams.find(function (e) {
                    return e.id == idTeam;
                  });
                }
              });
            else {
              for (let index = 0; index < data.table.length; index++) {
                const idTeam = data.table[index].team;
                data.table[index].team = this._teams.find(function (e) {
                  return e.id == idTeam;
                });
              }
            }

            this.load = true;
            this.loaddingData = false;

            this.table = this.sortByKey(this.table, "gf");
            this.table = this.sortByKey(this.table, "dg");
            this.table = this.sortByKey(this.table, "pts");
          }
        });
    }
  }

  table = [];
  generate(matchs) {
    if (this._idCat == "-1") {
      this.table = this.generateGeneralTable(matchs);
      console.log(this._idCat);
    } else {
      console.log(this._idCat);
      this.table = this.generateCategoryTable(this._idCat, matchs);
    }

    // if(!(Object.entries(this.dataService.selectedTeam).length === 0 && this.dataService.selectedTeam.constructor === Object)){
    //   let idTeamSelect = this.dataService.selectedTeam.id;
    //   alert(idTeamSelect);
    // this.table = this.table.sort(function(a,b){
    //   if(a.team.id == idTeamSelect)
    //   return -1;
    //   else if(b.team.id == idTeamSelect)
    //   return 1;

    //   return 0;
    // })
    // }

    this.loaddingData = false;
  }
  generateTable() {
    var tableFound;
    if (
      (tableFound = this.persistent.findOnTablas(
        this._idCat,
        this._idFutbolEvent
      ))
    ) {
      console.log(tableFound);
      this.table = tableFound;
    } else {
      const journys = this.torneoSv.tbgj();
      var ids = journys.map(function (x) {
        return x.id;
      });
      this.http.getFutbolEvent(this._idFutbolEvent).subscribe((fev) => {
        var idTeams = fev.teams.map(function (x) {
          return x.id;
        });
        this.http.getTeamsById(idTeams).subscribe((teams) => {
          this.teams = teams;
          this.http.getMatchsByJourneys(ids).subscribe((mat) => {
            this.generate(mat);
          });
        });
      });
    }
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }

  sortByDobleKey(array, parentKey, childKey) {
    return array.sort(function (a, b) {
      var x = a[parentKey][childKey];
      var y = b[parentKey][childKey];
      return x > y ? 1 : x < y ? -1 : 0;
    });
  }

  generateCategoryTable(Idcateogory, matchs) {
    let teamsTables = [];

    for (let idxTeam = 0; idxTeam < this.teams.length; idxTeam++) {
      let tableTeam = {
        pts: 0,
        pj: 0,
        g: 0,
        e: 0,
        p: 0,
        team: [],
      };
      let team = JSON.parse(JSON.stringify(this.teams[idxTeam]));
      var ifCat = team.categories.find((x) => x.id === Idcateogory);
      if (ifCat) {
        tableTeam["team"] = team;
        for (let idxMatch = 0; idxMatch < matchs.length; idxMatch++) {
          let match = matchs[idxMatch];
          if (
            match.category.id == Idcateogory &&
            match.finish &&
            (team.id == match.teamLocal.id || team.id == match.teamVisitor.id)
          ) {
            tableTeam.pj++;
            if (match.win && team.id == match.win.id) {
              tableTeam.pts += 3;
              tableTeam.g++;
            } else if (match.tie == true) {
              tableTeam.pts += 1;
              tableTeam.e++;
            } else tableTeam.p++;
          }
        }
        teamsTables.push(tableTeam);
      }
    }

    this.load = true;

    teamsTables = this.sortByDobleKey(teamsTables, "team", "name");
    teamsTables = this.sortByKey(teamsTables, "pj");
    return this.sortByKey(teamsTables, "pts");
  }

  teams;
  journeys;
  load;
  generateGeneralTable(matchs) {
    let teamsTables = [];
    for (let idxTeam = 0; idxTeam < this.teams.length; idxTeam++) {
      let tableTeam = {
        pts: 0,
        pj: 0,
        g: 0,
        e: 0,
        p: 0,
      };
      let team = this.teams[idxTeam];
      tableTeam["team"] = team;

      for (
        let idxJourney = 0;
        idxJourney < this.journeys.length;
        idxJourney++
      ) {
        let journey = this.journeys[idxJourney];
        for (let idxMatch = 0; idxMatch < journey.matchs.length; idxMatch++) {
          let match = journey.matchs[idxMatch];

          if (
            match.finish &&
            (team.id == match.teamLocal.id || team.id == match.teamVisitor.id)
          ) {
            tableTeam.pj++;
            if (team.id == match.win) {
              tableTeam.pts += 3;
              tableTeam.g++;
            } else if (match.tie == true) {
              tableTeam.pts += 1;
              tableTeam.e++;
            } else tableTeam.p++;
          }
        }
      }
      teamsTables.push(tableTeam);
    }

    this.load = true;

    teamsTables = this.sortByDobleKey(teamsTables, "team", "name");
    teamsTables = this.sortByKey(teamsTables, "pj");
    console.log(teamsTables);
    return this.sortByKey(teamsTables, "pts");
  }
}
