import { Component, OnInit } from "@angular/core";
import { DataService } from "../../service/data.service";
import { HttpService } from "../../service/http.service";

@Component({
  selector: "menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  constructor(public dataSv: DataService) {}

  teams = [];
  reglamento;
  selectedTeam;
  slideConfig = {
    slidesToShow: 20,
    slidesToScroll: 3,
    infinite: false,

    prevArrow:
      "<i class='fas fa-arrow-left' style='float: left; margin-top: 10px; margin-right: 10px; font-size: 20px;'>",
    nextArrow:
      "<i class='fas fa-arrow-right'  style='float: right; margin-top: -30px; margin-right: -20px; font-size: 20px;'>",
  };
  ngOnInit() {
    this.teams = this.dataSv.teamsShow;
    this.reglamento = this.dataSv.getReglamento();
  }

  goToInstagram() {
    window.open("https://www.instagram.com/futsala.ok/?hl=es-la");
  }
}
