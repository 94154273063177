import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "general-news",
  templateUrl:'./general-news.component.html',
  styles: [],
})
export class GeneralNewsComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() srcImage: string;

  public date = new Date().getFullYear();
}
