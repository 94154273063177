import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  private urlHost = environment.baseUrl;
  private urlActive = this.urlHost;

  loginWeb(login): Observable<any> {
    const params = new HttpParams()
      .set("username", login.username)
      .set("password", login.password); // Create new HttpParams
    return this.http.post<any>(this.urlActive + "/web-login", params);
  }

  public selectPlayersTeam$(idTeam): Observable<any[]> {
    console.log(idTeam);
    const params = new HttpParams().set("idTeam", idTeam);
    return this.http.get<any[]>(this.urlActive + "/player/team", { params });
  }

  getPlayer(idPlayer): Observable<any> {
    const params = new HttpParams().set("idPlayer", idPlayer); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getPlayer", { params });
  }
  getNews(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/web-getNews");
  }
  getPlayOff(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/getAllPlayOff");
  }

  getReglamento(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/web-getReglamento");
  }
  public generatePdf(code): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    const params = new HttpParams().set("code", code);
    return this.http.post<any>(this.urlActive + "/generatePortrait", params, {
      headers,
      responseType: "blob" as "json",
    });
  }

  getTribunales(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/web-getTribunales");
  }

  getMatch(idMatch): Observable<any> {
    const params = new HttpParams().set("idMatch", idMatch); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getMatch", { params });
  }

  postContacto(contacto): Observable<any> {
    const params = new HttpParams()
      .set("sms", contacto.sms)
      .set("name", contacto.name)
      .set("motivo", contacto.motivo)
      .set("email", contacto.email); // Create new HttpParams
    return this.http.post<any>(this.urlActive + "/web-postContacto", params);
  }

  getTeams(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/web-getTeams");
  }

  getTeam(id): Observable<any> {
    const params = new HttpParams().set("id", id); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getTeam", { params });
  }

  getTablePosition(idFutbol, idCat) {
    const params = new HttpParams()
      .set("idFutbolEvent", idFutbol)
      .set("idCategory", idCat); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getTablePosition", {
      params,
    });
  }
  getTorneos(): Observable<any> {
    return this.http.get<any>(this.urlActive + "/web-getTorneos");
  }

  getFixture(id): Observable<any> {
    const params = new HttpParams().set("id", id); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getFixture", { params });
  }

  getCategorias(idEvent): Observable<any> {
    if (!idEvent) {
      return null;
    }

    return this.http.get<any>(this.urlActive + "/web-getCategories");
  }

  getJornada(idJourney): Observable<any> {
    if (!idJourney) {
      return null;
    }

    const params = new HttpParams().set("idJourney", idJourney); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getJourney", { params });
  }

  getFutbolEvent(idEvent): Observable<any> {
    if (!idEvent) {
      return null;
    }
    const params = new HttpParams().set("idFutbolEvent", idEvent); // Create new HttpParams

    return this.http.get<any>(
      this.urlActive + "/web-getFutbolEventCompetition",
      { params }
    );
  }

  getMatchs(idEvent): Observable<any> {
    if (!idEvent) {
      return null;
    }
    const params = new HttpParams().set("idFutbolEvent", idEvent); // Create new HttpParams

    return this.http.get<any>(this.urlActive + "/web-getMatchs", { params });
  }

  getMatchsById(idMatchs): Observable<any> {
    if (!idMatchs || idMatchs.lenght == 0) {
      return null;
    }
    const params = new HttpParams().set("idMatchs", idMatchs); // Create new HttpParams

    return this.http.get<any>(this.urlActive + "/web-getMatchsById", {
      params,
    });
  }

  getMatchsByJourneys(idJourneys): Observable<any> {
    if (!idJourneys || idJourneys.lenght == 0) {
      return null;
    }
    const params = new HttpParams().set("idJourneys", idJourneys); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getMatchsByJourneys", {
      params,
    });
  }

  getTeamsById(idTeams): Observable<any> {
    if (!idTeams || idTeams.lenght == 0) {
      return null;
    }
    const params = new HttpParams().set("idTeams", idTeams); // Create new HttpParams
    return this.http.get<any>(this.urlActive + "/web-getTeamsById", { params });
  }

  getContenedores() {
    return this.http.get<any>(this.urlActive + "/web-getContenedores");
  }
}
