import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import { MenuComponent } from "./utils/menu/menu.component";
import { HttpClientModule } from "@angular/common/http";

import { MenuSmComponent } from "./utils/menu-sm/menu-sm.component";
import { UtilsModule } from "./utils/utils.module";
import { WebpageComponent } from "./webpage/webpage.component";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MenuSmComponent,
    WebpageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UtilsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
