import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WebpageComponent } from "./webpage/webpage.component";

const routes: Routes = [
  {
    path: "",
    component: WebpageComponent,
    children: [
      {
        path: "",
        loadChildren: "./home/home.module#HomeModule",
      },
      {
        path: "torneos",
        loadChildren: "./pages/torneos/torneos.module#TorneosModule",
      },
      {
        path: "partido",
        loadChildren: "./pages/partido/partido.module#PartidoModule",
      },
      {
        path: "jugador",
        loadChildren: "./pages/jugador/jugador.module#JugadorModule",
      },
      {
        path: "tabla",
        loadChildren: "./pages/tabla/tabla.module#TablaModule",
      },
      {
        path: "agenda",
        loadChildren: "./pages/agenda/agenda.module#AgendaModule",
      },
      {
        path: "equipos",
        loadChildren: "./pages/equipos/equipos.module#EquiposModule",
      },
      {
        path: "fixture",
        loadChildren: "./pages/fixture/fixture.module#FixtureModule",
      },
      {
        path: "contacto",
        loadChildren: "./pages/contacto/contacto.module#ContactoModule",
      },
      {
        path: "tribunal",
        loadChildren: "./pages/tribunal/tribunal.module#TribunalModule",
      },
      {
        path: "playoff",
        loadChildren: "./pages/playoff/playoff.module#PlayoffModule",
      },
    ],
  },
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
