import { Component, OnInit } from "@angular/core";

@Component({
  selector: "match-news",
  templateUrl: "./match-news.component.html",
  styleUrls: ["./match-news.component.css"],
})
export class MatchNewsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
