import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-video-news",
  template: ` <p>video-news works!</p> `,
  styles: [],
})
export class VideoNewsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
