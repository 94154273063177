import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GeneralNewsComponent } from "./general-news/general-news.component";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {
  MatListModule,
  MatToolbarModule,
  MatTabsModule,
  MatSidenavModule,
  MatDialogModule,
} from "@angular/material";
import { TablePositionSmComponent } from "./table-position/table-positionSm.component";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TablePositionMdComponent } from "./table-position/table-position-md.component";
import { VideoNewsComponent } from "./video-news/video-news.component";
import { MatchNewsComponent } from "./match-news/match-news.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    GeneralNewsComponent,
    TablePositionSmComponent,
    TablePositionMdComponent,
    VideoNewsComponent,
    MatchNewsComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatToolbarModule,
    MatTabsModule,
    FormsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    MatToolbarModule,
    MatTabsModule,
    MatSelectModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    GeneralNewsComponent,
    TablePositionSmComponent,
    TablePositionMdComponent,
    MatchNewsComponent,
    VideoNewsComponent,
  ],
})
export class UtilsModule {}
