import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class TorneoService {
  constructor(private http: HttpService) {
    this.http.getTorneos().subscribe((data) => {
      this._torneos = data;
    });
  }

  _torneos;
  futbolEventSelect = {} as any;

  torneos = [] as any;

  torneoSelect = {} as any;
  categorySelect = null;
  journeySelect = null;

  contenedores: any;
  contenedorSelect: any;

  categoriesTorneoSelect = [];
  journeysTorneoSelect = [];
  matchsJourneySelect = [];

  loadFinish = true;
  private _matchsSelect = [];
  private _journeys;

  restart() {
    this.futbolEventSelect = {} as any;
    this.torneoSelect = {} as any;
    this.categorySelect = null;
    this.journeySelect = null;
  }

  isEmptySelected() {
    if (!this.torneoSelect || !this.categorySelect || !this.journeySelect) {
      return true;
    }

    const r1 =
      Object.entries(this.torneoSelect).length === 0 &&
      this.torneoSelect.constructor === Object;
    const r2 =
      Object.entries(this.categorySelect).length === 0 &&
      this.categorySelect.constructor === Object;
    const r3 =
      Object.entries(this.journeySelect).length === 0 &&
      this.journeySelect.constructor === Object;

    if (r1 && r2 && r3) {
      return true;
    }

    return false;
  }

  onContenedorSelectChange(contenedor) {
    this.loadFinish = false;
    this.matchsJourneySelect = [];

    this.torneos = this._torneos.filter((torneo) => {
      return torneo.contenedor == contenedor.id;
    });

    const torneoContenedor = this.torneos.filter((torneo) => {
      torneo.contenedor == contenedor.id;
    });

    if (torneoContenedor.length > 0) {
      this.onTorneoSelectChange(torneoContenedor[0]);
      this.torneoSelect = torneoContenedor[0];
    }

    this.categoriesTorneoSelect = [];

    this.categorySelect = null;
    this.journeySelect = null;

    this.loadFinish = true;
  }

  onTorneoSelectChange(torneo) {
    this.loadFinish = false;
    this.matchsJourneySelect = [];
    this.torneoSelect = torneo;
    this.categoriesTorneoSelect = [];

    this.categorySelect = null;
    this.journeySelect = null;

    if (torneo.futbolEvent) {
      this.getFutbolEvent(torneo.futbolEvent);
    }

    this.loadFinish = true;
  }

  onCategoriaSelectChange(categoria) {
    this.categorySelect = categoria;
    if (categoria == -1) {
    } else {
      if (this.matchsJourneySelect.length > 0) {
        this.matchsJourneySelect = this.filterMatchsByCategory(categoria.id);
      }
    }
  }

  onJornadaSelectChange(jornada) {
    this.journeySelect = jornada;
    this.matchsJourneySelect = [];
    this.getJourney(jornada.id);
  }

  filterMatchsByCategory(idCategory) {
    this.matchsJourneySelect = this._matchsSelect;
    let filterMatchs;
    filterMatchs = this.matchsJourneySelect.filter((x) => {
      return x.category.id == idCategory;
    });
    return filterMatchs;
  }

  orderMatchByDate() {
    this.matchsJourneySelect = this.matchsJourneySelect.sort(function (a, b) {
      if (!a.date && b.date) {
        return 1;
      }

      if (a.date && !b.date) {
        return -1;
      }

      if (!a.date && !b.date) {
        return 0;
      }

      const dateA = moment(a.date + a.time, "YYYY-MM-DD HH:mm");
      const dateB = moment(b.date + "" + b.time, "YYYY-MM-DD HH:mm");
      return moment(dateA).diff(dateB);
    });
  }

  getJourney(idJourney) {
    this.loadFinish = false;
    this.http.getJornada(idJourney).subscribe((data) => {
      // DELETEdata.matchs);
      const idMatchs = data.matchs.map(function (x) {
        return x.id;
      });

      this.http.getMatchsById(idMatchs).subscribe((dat) => {
        // DELETEdat);
        this.matchsJourneySelect = dat;
        this._matchsSelect = dat;
        if (this.categorySelect) {
          this.matchsJourneySelect = this.filterMatchsByCategory(
            this.categorySelect.id
          );
        }

        this.loadFinish = true;
        this.orderMatchByDate();
      });
    });
  }

  getFutbolEvent(idEvent) {
    this.loadFinish = false;
    this.http.getFutbolEvent(idEvent).subscribe((data) => {
      this.futbolEventSelect = data;

      this.categoriesTorneoSelect = data.categories;
      if (data.categories.length > 0) {
        this.onCategoriaSelectChange(data.categories[0]);
      }

      this.journeysTorneoSelect = data.journeys;
      if (data.journeys.length > 0) {
        for (let index = 0; index < data.journeys.length; index++) {
          if (!data.journeys[index].finish) {
            this.journeySelect = data.journeys[index];
            this.onJornadaSelectChange(data.journeys[index]);
            break;
          }
        }
      }

      this._journeys = data.journeys;
      console.log(data);
      this.loadFinish = true;
    });
  }

  destroy() {
    this.categoriesTorneoSelect = [];
    this.journeysTorneoSelect = [];
  }

  tbgj() {
    return this._journeys;
  }
}
