import { Component, OnInit } from "@angular/core";
import { DataService } from "../service/data.service";
import { HttpService } from "../service/http.service";

@Component({
  selector: "app-webpage",
  templateUrl: "./webpage.component.html",
  styleUrls: ["./webpage.component.css"],
})
export class WebpageComponent implements OnInit {
  constructor(private http: HttpService, private dtaService: DataService) {}

  innerWidth: any;
  needRouter = false;

  statusWB = false;
  loadError = false;
  err = 0;

  title = "lifang";
  ngOnInit(): void {
    this.loadData();

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 800) {
      this.needRouter = true;
    }
  }
  goToInstagram() {
    window.open("https://www.instagram.com/futsala.ok/?hl=es-la");
  }

  loadData() {
    this.http.getTeams().subscribe(
      (data) => {
        this.dtaService.teams = data.slice();
        this.dtaService.teamsShow = data.slice();
        let idLibre = "5c7de594fb9d9320997a41af";
        this.dtaService.teamsShow.splice(
          this.dtaService.teamsShow.findIndex((e) => e.id == idLibre),
          1
        );
        this.dtaService.sortTeams();
        this.statusWB = true;
        this.loadError = false;
        this.err = 0;
      },
      (err) => {
        this.err++;
        if (this.err > 2) {
          this.loadError = true;
        } else {
          setTimeout(() => {
            this.loadData();
          }, 2000);
        }
      }
    );
  }
}
